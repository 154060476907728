<template>
  <section>
    <div class="content-header">
      <h2>Indigenous resources</h2>
    </div>
    <div class="content-wrapper">
      <p>Learn more about Indigenous culture and history through an Open Educational Resource from Centennial College: <a href="https://www.centennialcollege.ca/indigenous-education/our-stories-first-peoples-in-canada/" target="_blank">Our Stories: First Peoples in Canada</a>.</p>
      <p>Learn more about Indigenous students’ needs in the classroom, in the video, <a href="https://www.youtube.com/watch?v=g8h1cODvA9g" target="_blank">What I wish my professors knew about me…</a> featuring diverse Indigenous student voices. This video was curated and produced by Dr. Chantelle Richmond, an Indigenous Health Geographer at Western University. </p>
      <p>Ensure your work is reflective of the diversity of voices within Indigenous communities, including Afro-indigenous and Black Native perspectives. An excellent resource for listening to digital stories and reflecting on complex histories of colonization can be found through the Proclaiming our Roots initiative, including “<a href="https://www.proclaimingourroots.com/partners" target="_blank">personal and collective truths, insights and memories [of] Black-Indigenous peoples on Turtle Island</a>”. </p>
      <p>Focus on learning more about the experience of Two-Spirit, Lesbian, Gay, Transgender, Queer, Interspirit, Asexual (2SLBGTQIA+), and Indigenous community members navigating diverse sexual orientations and identities through:</p>
      <ul>
        <li><a href="https://2spiritsinmotion.com/" target="_blank">2 Spirits in Motion Society</a>: A national organization working to capture and advance the perspectives of diverse 2S community members</li>
        <li><a href="https://2spirits.org/" target="_blank">2-Spirited People of the 1st Nations</a>: An organization from the Greater Toronto Area that has existed since the 1990s and provides resources and programming for 2S community members</li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '@/components/Accordion.vue'
// import SidebarMenu from '@/components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: 'content',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
